<template>
  <div>
    <Button type="warning" class="m-r-sm" @click="preview">预览</Button>
    <Button
      :type="isLastView ? 'success' : 'primary'"
      @click="$emit('to-handle')"
      class="m-r-sm"
      v-if="userName === hb.userName"
      >修改</Button
    >

    <Button v-if="userName === hb.userName" @click="drop">放弃</Button>
    <Button v-else-if="!hb.userName" type="primary" @click="receive"
      >领取</Button
    >
    <span v-else>
      已被<span class="text-error">{{ hb.realName }}</span
      >领取
    </span>
  </div>
</template>

<script>
import homeService from '../api/homePageService'
import {getHbTaskView} from '../api/hbService' 
export default {
  props: {
    hb: {},    
    isLastView: { default: false },
  },
  computed: {
    userName() {
      return this.$store.state.userInfo?.username;
    },
    realName(){
      return this.$store.state.userInfo?.realname
    } 
  },
  methods: {
    receive() {
      homeService
        .receiveTask(
          this.hb.taskId,
          this.hb.processInstanceId,
          this.hb.taskName
        )
        .then(() => {
          this.hb.userName = this.userName;
          this.hb.realName = this.realName;
        });
    },
    drop() {
      homeService.dropTask(this.hb.taskId).then(() => {
        this.hb.userName = null;
        this.hb.realName = null;
      });
    },
    preview(){
      getHbTaskView({processId:this.hb.processInstanceId,bizId:this.hb.bizId,taskId:this.hb.taskId,contentType:this.hb.contentType}).then(res=>{
        this.$mobilePreview(res.content)
      })     
    }
  },
};
</script>

<style>
</style>